<template>
    <div class="howplay">
      <!-- <howplay-btn color="primary"></howplay-btn> -->
      <div class="banner">
        <img class="icon" src="@/assets/images/play_icon.png" alt="">
        {{ $t('common.howToPlay') }}
      </div>
      <div class="panel">
        <div v-for="content,i in $t('play')" :key="'how_play_'+i" class="item">
          <div v-if="i>=1" class="idx">{{i}}</div>
          <div class="text">{{content}}</div>
        </div>
        <div v-for="game,i in $t('playGame')" :key="'play_game_'+i" class="game-item">
          <mu-expansion-panel class="mepanel" :expand="panel === i" @change="toggle(i)">
            <div class="header" slot="header">
              <img class="icon" :src="'assets/game_icon_'+i+'/1x.png'" alt="">
              <span>{{$t('common.gameNames')[i]}}</span>
            </div>
            <div class="txt" v-for="t,ii in game" :key="'txt_'+ii">
              {{t}}
            </div>
          </mu-expansion-panel>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  name: 'Howplay',
  data(){
    return {
      panel: 0
    }
  },
  mounted(){
    this.$store.commit("setClosablePage",true);
  },
  components:{
  },
  methods:{
    toggle (panel) {
      this.panel = panel === this.panel ? '' : panel;
    }
  }
}
</script>
<style lang="less" scoped>
.howplay{
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: left;
  .banner {
    height: 140px;
    background-image: url("~@/assets/images/play_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 140px;
    color: #ffeec5;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      height: 50px;
      padding-right: 10px;
    }
  }
  .panel{
    background: @bg-white;
    // height: calc(100vh - 240px);
    // overflow-y: scroll;
    margin: 1.4*@base-pd;
    border-radius: 16px;
    color: @bg-color;
    padding: @base-pd;
    .item{
      position:relative;
      padding: 0.5*@base-pd 0;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 0.5*@base-pd;
      word-break: break-all;
      word-wrap: break-word;
      .idx{
        background: @primary-color;
        color: @bg-white;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0px 10px 20px 0px rgba(254,193,56,0.35);
        font-size: 20px;
        margin-right: 7px;
        position: absolute
      }
      .text{
        padding-left: 70px;
        // display: inline-block;
      }:first-child{
        padding-left: 0;
      }
    }:last-child{
      border-bottom: 0;
    }
    .game-item{
      margin-bottom: @base-pd;
      .mu-paper{
        background: @bg-color;
        color: @bg-white;
        border-radius: 16px;
        font-size: 24px; 
      }
      .mu-expansion-panel-header{
        
        // padding: @base-pd;
        .header{
          display:flex;
          align-items: center;
          padding: @base-pd 0;
          font-size: 24px;
          .icon{
            width: 80px;
            padding-right: @base-pd;
          }
        }
        button.mu-button{
          color: #fff !important;
        }
        
      }
      .mepanel{
        .mu-expansion-panel-header{
          .mu-expansion-toggle-btn.mu-button svg{
            color: #ffffff;
          }
        }
      }
      .txt{
        text-indent: 2em;
      }
    }
    .game-item .mu-icon-button{
      color: @bg-white !important;
    }
  }
}

</style>
